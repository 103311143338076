.header-h {
  height: 64px;
}
.header-menu-top {
  top: 64px;
}
.header-space {
  margin-top: 64px;
}

.header-visible {
  transform: translateY(0);
  transition-delay: 0.1s;
}

.header-hidden {
  transform: translateY(-100%);
  transition-delay: 0.1s;
}
